import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, Outlet, useNavigate } from "react-router-dom";

const Login = React.lazy(() => import('./components/Login/loginpage.jsx'))
const Layout = React.lazy(() => import('./components/Layout/layout.jsx'))

const Home = React.lazy(() => import('./components/Home/home.jsx'));
const PUMCHome = React.lazy(() => import('./components/PUMCHome/home.jsx'));
const UserReports = React.lazy(() => import('./components/UserReports/userReports.jsx'));
const Financials = React.lazy(() => import('./components/Financials/financials.jsx'));
const Contracts = React.lazy(() => import('./components/Contracts/contracts.jsx'));
const AISolutions = React.lazy(() => import('./components/AISolutions/aiSolutions.jsx'));
const Employees = React.lazy(() => import('./components/Employees/employees.jsx'));
const DataMgt = React.lazy(()=> import('./components/DataManagement/dataManagement.jsx') );
const PLFileUpload = React.lazy(()=> import('./components/DataManagement/PLFileUpload/plFileUpload.jsx'))
const CustomersDashboard = React.lazy(()=> import('./components/Customers/customers.jsx'))
const AIAnomalyDetection = React.lazy(()=> import('./components/Property/AIAnomali/aiAnomali.jsx'))

const AnalyticStudio = React.lazy(() => import('./components/AnalyticStudio/analyticStudio.jsx'));


const NonAssociatedGasProduction = React.lazy(() => import('./components/Home/nonAssociatedGasProduction.jsx'))
const Reserves = React.lazy(() => import('./components/Home/reserves.jsx'))
const OperationalExcellence = React.lazy(() => import('./components/Home/operationalExcellence.jsx'))
const Portfolio = React.lazy(() => import('./components/Home/portfolio.jsx'))
const TechnologyCapability = React.lazy(() => import('./components/Home/technologyCapability.jsx'))
const Stakeholders = React.lazy(() => import('./components/Home/stakeholders.jsx'))

const Administration = React.lazy(() => import("./components/Administration/administration.jsx"))

const ForgotPassword = React.lazy(()=> import ('./components/Login/forgotpassword.jsx'))
const UserManagement = React.lazy(() => import('./components/UserManagement/index.jsx'))
const Insights = React.lazy(() => import('./components/Insights/insights.jsx'))
const PUMCInsights = React.lazy(() => import('./components/PUMCInsights/insights.jsx'))
const AssetManagement = React.lazy(()=> import('./components/AssetManagement/assetManagement.jsx'))

const InitiativesLanding = React.lazy(()=> import ('./components/Initiatives/index.jsx'))
const CreateInitiativesLanding = React.lazy(()=> import ('./components/Initiatives/create.jsx'))
const CadenceBoardInitiativesLanding = React.lazy(()=> import ('./components/Initiatives/cadenceBoard.jsx'))
const ViewInitiativesLanding = React.lazy(()=> import ('./components/Initiatives/view.jsx'))


const PrivateRoutes = () => {
  const auth = localStorage.getItem('loggedInUser')
  return (
    auth ? <Outlet /> : <Navigate to='/login' />
  )
}

const PrivateRoutesPWA = () => {
  const auth = localStorage.getItem('loggedInUser')
  return (
    auth ? <Outlet /> : <Navigate to='/pwa-login' />
  )
}

function Loading() {
  return <h2>🌀 Loading...</h2>;
}

export default function Router() {

  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ForgotPassword />} />



       

          <Route element={<PrivateRoutes />}>
            <Route path="/home/*" element={<Layout />} />



              <Route path="/app" element={<Layout />} >
              <Route index element={<PUMCHome />} />
              <Route path="/app/insights" element={<Insights />} />
              <Route path="/app/detailed-insights" element={<PUMCInsights />} />
              <Route path="user-management" element={<UserManagement />} />
              <Route path="/app/financials" element={<Financials />}/>
              <Route path="/app/user-reports" element={<UserReports />} />
              <Route path="/app/analytic-studio" element={<AnalyticStudio/>} />
              <Route path="/app/planning" element={<AISolutions />}/>
              <Route path="/app/employees" element={<Employees />}/>
              <Route path ='/app/asset-management' element={<AssetManagement />} />
              <Route path="/app/data-mgt" element={<DataMgt />}/>
              <Route path="/app/data-mgt/pl-file-upload" element={<PLFileUpload />}/>
              <Route path="/app/data-mgt/ai-anomaly-detection" element={<AIAnomalyDetection />}/>

              <Route path ='/app/gas-production' element={<Home />} />
              <Route path ='/app/reserves' element={<Reserves />} />
              <Route path ='operational-excellence' element={<OperationalExcellence />} />
              <Route path ='/app/portfolio' element={<Portfolio />} />
              <Route path ='/app/technology-capability' element={<TechnologyCapability />} />
              <Route path ='/app/stakeholders' element={<Stakeholders />} />


              <Route path="/app/contracts" element={<Contracts />} />
              
              <Route path="/app/customers" element={<CustomersDashboard />} />

              <Route path ='/app/initiatives' element={<InitiativesLanding />} />
              <Route path ='/app/initiatives/create' element={<CreateInitiativesLanding />} />
              <Route path ='/app/initiatives/cadence-board' element={<CadenceBoardInitiativesLanding />} />
              <Route path ='/app/initiatives/view' element={<ViewInitiativesLanding />} />
            </Route>
          </Route>

          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>

  );
}
